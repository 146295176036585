import React from "react";
import { Link } from "react-router-dom";
import "./pageName.scss";


const PageName = ({breadcrumbs}) => {


    return(
        <div className="pagename_container">
            <div className="pagename_box">
                <h3>{breadcrumbs}</h3>

                <p><Link to="/">Forside</Link> &#62; <span>{breadcrumbs}</span></p>
            </div>
        </div>
    );
}
export default PageName;