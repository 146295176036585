import React, {useState, useEffect} from "react";
import AmountPerPage from "../../components/amountperpage/AmountPerPage";
import Pagination from "../../components/pagination/Pagination";
import { getAllNews } from "../../api/apifetch";
import n4 from "../../assets/img/4n.jpg";
import n5 from "../../assets/img/5n.jpg";
import n6 from "../../assets/img/6n.jpg";
import n7 from "../../assets/img/7n.jpg";
import n10 from "../../assets/img/10n.jpg";
import n11 from "../../assets/img/11n.jpg";
import n12 from "../../assets/img/12n.jpg";
import n13 from "../../assets/img/13n.jpg";
import "./allNews.scss";


const AllNews = () => {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [news, setNews] = useState();
    const [itemsPerPage, setItemsPerPage] = useState(4);
    const [currentPage, setCurrentPage] = useState(0);


    useEffect(() => {
        
        setLoading(true)

        getAllNews().then((data) => {

            console.log(data)
            setNews(data.sort((a, b) => {
                
                return Date.parse(b.received) -  Date.parse(a.received);
                
            }))
        })

        .catch((err) => {

            setError(true)
        })
        
        .finally(() => {

            setLoading(false)
        })

    }, [] )

    return(
        <div className="allnews-container">

            <div className="allnews-center-container">

                <div className="allnews_split-container">
            
                

                <div className="allnews_flex-container">

                <div className="allnews_box">
                    <span className="lastnews_bookmark">{String.fromCharCode(0xf02e)}</span>
                    <div className="lastnews_date">25 may.</div>
                    <img src={n4} alt="" width="100%" />

                    <h3>Ny lærling</h3>

                    <p>Lorem ipsum dolor amet consectetur adipisicing elit sed eiusm tempor incididunt ut labore dolore magna aliqua enim ad minim</p>
                    <div className="allnews_comments-container">
                    <div className="allnews_comments"><p>{String.fromCharCode(0xf086)} 2 Kommentar</p></div>
                    </div>
                </div>



                <div className="allnews_box">
                    <span className="lastnews_bookmark">{String.fromCharCode(0xf02e)}</span>
                    <div className="lastnews_date">12 Jun.</div>
                    <img src={n5} alt="" width="100%" />

                    <h3>Test dit forbrug</h3>

                    <p>Lorem ipsum dolor amet consectetur adipisicing elit sed eiusm tempor incididunt ut labore dolore magna aliqua enim ad minim</p>
                    <div className="allnews_comments-container">
                    <div className="allnews_comments"><p>{String.fromCharCode(0xf086)} 30 Kommentar</p></div>
                    </div>
                </div>



                <div className="allnews_box">
                    <span className="lastnews_bookmark">{String.fromCharCode(0xf02e)}</span>
                    <div className="lastnews_date">04 Jul.</div>
                    <img src={n6} alt="" width="100%" />

                    <h3>Få tjekket din varmepumpe</h3>

                    <p>Lorem ipsum dolor amet consectetur adipisicing elit sed eiusm tempor incididunt ut labore dolore magna aliqua enim ad minim</p>
                    <div className="allnews_comments-container">
                    <div className="allnews_comments"><p>{String.fromCharCode(0xf086)} 30 Kommentar</p></div>
                    </div>
                </div>



                <div className="allnews_box">
                    <span className="lastnews_bookmark">{String.fromCharCode(0xf02e)}</span>
                    <div className="lastnews_date">06 Aug.</div>
                    <img src={n7} alt="" width="100%" />

                    <h3>Nye tider</h3>

                    <p>Lorem ipsum dolor amet consectetur adipisicing elit sed eiusm tempor incididunt ut labore dolore magna aliqua enim ad minim</p>
                    <div className="allnews_comments-container">
                    <div className="allnews_comments"><p>{String.fromCharCode(0xf086)} 19 Kommentar</p></div>
                    </div>
                </div>

            </div>
            

            <div className="allnews_arkiv-container">

            <h4 className="allnews_arkiv-font-text">Arkiv</h4>

                <div className="allnews_arkiv-box">

                    <div className="allnews_arkiv-img">
                        <img src={n10} alt="" width="100%" />
                    </div>

                    <div className="allnews_arkiv-text">
                        <h4>Totam Rem Aperiam Eaque<br/>Ipsa Quae ab Illo.</h4>
                        <p><span>{String.fromCharCode(0xf073)}</span> 02 Feb, 2022</p>
                    </div>
                </div>



                <div className="allnews_arkiv-box">

                    <div className="allnews_arkiv-img">
                        <img src={n11} alt="" width="100%" />
                    </div>

                    <div className="allnews_arkiv-text">
                        <h4>Totam Rem Aperiam Eaque<br/>Ipsa Quae ab Illo.</h4>
                        <p><span>{String.fromCharCode(0xf073)}</span> 05 Feb, 2022</p>
                    </div>
                </div>



                <div className="allnews_arkiv-box">

                    <div className="allnews_arkiv-img">
                        <img src={n12} alt="" width="100%" />
                    </div>

                    <div className="allnews_arkiv-text">
                        <h4>Totam Rem Aperiam Eaque<br/>Ipsa Quae ab Illo.</h4>
                        <p><span>{String.fromCharCode(0xf073)}</span> 12 Feb, 2022</p>
                    </div>
                </div>



                <div className="allnews_arkiv-box">

                    <div className="allnews_arkiv-img">
                        <img src={n13} alt="" width="100%" />
                    </div>

                    <div className="allnews_arkiv-text">
                        <h4>Totam Rem Aperiam Eaque<br/>Ipsa Quae ab Illo.</h4>
                        <p><span>{String.fromCharCode(0xf073)}</span> 02 May, 2022</p>
                    </div>
                </div>
           
            </div>
            </div>
            <div className="allnews_pagination">
            <Pagination itemsPerPage={itemsPerPage} currentPage={currentPage} setCurrentPage={setCurrentPage} />

            <AmountPerPage setItemsPerPage={setItemsPerPage} options={[ 2, 5, 6 ]} />
            </div>
            </div>
            
        </div>
    );
}
export default AllNews;