import React, {useState, useEffect} from "react";
import "./header.scss";
import logo from "../../assets/img/logo.png";
import { getAllContactInFormation } from "../../api/apifetch";


const Header = () => {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [header, setHeader] = useState();


    useEffect(() => {
        
        setLoading(true)

        getAllContactInFormation().then((data) => {

            console.log(data)
            setHeader(data)
        })

        .catch((err) => {

            setError(true)
        })
        
        .finally(() => {

            setLoading(false)
        })

    }, [] )

    return(
        <div className="header_container">
        
            <div className="center-header_container">
                <img src={logo} alt="" />
                <div className="header_contactinformation">
                <h4><span>{String.fromCharCode(0xf041)}</span> Strømparken 1, 8500 Grenaa.</h4>
                <h4><span>{String.fromCharCode(0xf017)}</span> Man - Søn 9.00 - 18.00</h4>
                <h4><span>{String.fromCharCode(0xf095)}</span> [45] 69 16 68 56 14</h4>
                </div>
            </div>
        
        </div>
    );
}
export default Header;