import React, { useState, useEffect } from "react";
import { getAllContactInFormation, createContact } from "../../api/apifetch";
import "./contact.scss";


const Contact = () => {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [header, setHeader] = useState();


    useEffect(() => {

        setLoading(true)

        getAllContactInFormation().then((data) => {

            console.log(data)
            setHeader(data)
        })

            .catch((err) => {

                setError(true)
            })

            .finally(() => {

                setLoading(false)
            })

    }, [])


    const handleSubmit = (e) => {

        e.preventDefault()
        
        setLoading(true)

        let formData = new FormData(e.target)

        createContact(formData)

        .then((response) => {
            console.log(response)
        })

        .catch((err) => {
            console.log(err)
        })

        .finally(() => {
            setLoading(false)
        })

    }

    return (
        <div className="contact_container">
            <div className="contact_center-container">
                 <div className="conatct_info-container">
                        <div className="conatct_info-box">
                            <div className="conatct-icon">
                            <span>{String.fromCharCode(0xf041)}</span>
                            </div>
                            <div className="contact-info">
                                <h4>Adresse</h4>
                                <p>Strømparken 1, 8500 Grenaa.</p>
                            </div>

                        </div>
                        <div className="conatct_info-box">
                            <div className="conatct-icon">
                            <span>{String.fromCharCode(0xf095)}</span>
                            </div>
                            <div className="contact-info">
                                <h4>Telefon</h4>
                                <p>[45] 69 16 68 56 14</p>
                            </div>
                        </div>
                        <div className="conatct_info-box">
                            <div className="conatct-icon">
                            <span>{String.fromCharCode(0xf0e0)}</span>
                            </div>
                            <div className="contact-info-none">
                                <h4>email</h4>
                                <p>info@strøm.dk</p>
                            </div>
                        </div>
                    </div>
                
                <form onSubmit={handleSubmit}>
                <div className="contact_form-container">
                    <div className="contact_form-boxcontainer">
                    <div className="contact_form-box">
                        <div className="contact_font-text">
                        <h3>Kontakt os</h3>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's</p>
                        </div>
                        <div className="contact_input">
                           <input type="text" name="name" placeholder="Dit navn" required />
                        </div>
                        <div className="contact_input">
                           <input type="email" name="email" placeholder="Din Email" required />
                        </div>
                        <div className="contact_input">
                           <input type="tel" name="phone" placeholder="Telefon nr." required />
                        </div>
                        <div className="contact_big-input">
                            <textarea type="text" name="message" placeholder="Din besked" />
                        </div>
                        <div className="contact_button">
                            <button type="submit">Send besked</button>
                        </div>
                    </div>
                    </div>
                    <div className="contact_googlemaps-box">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d141197.0274457802!2d10.699349513257905!3d56.42979075330917!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x464dd5ba03117935%3A0xa00afcc1d50fee0!2s8500%20Gren%C3%A5!5e0!3m2!1sda!2sdk!4v1668208711444!5m2!1sda!2sdk" width="100%" height="330px" />
                    </div>
                </div>
                </form>
            </div>
        </div>
    );
}
export default Contact;