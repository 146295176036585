import React from "react";
import "./pagination.scss";


const Pagination = (props) => {


    let currentPage = props.currentPage;
    let setCurrentPage = props.setCurrentPage;

    

    
    return (
        <div>

            <button className="pagination-arrow_button" onClick={() => setCurrentPage(currentPage - 1)}>Prev</button>

            {
                [...Array()].map((x, i) =>
                    <button className={i === currentPage ? "page-active" : "pagination_button"} key={i} onClick={() => setCurrentPage(i)}>{i + 1}</button>
                )
            }


            <button className="pagination-arrow_button" onClick={() => setCurrentPage(currentPage + 1)}>Next</button>

        </div>
    );
}
export default Pagination;