export const faqs = [
    {
      question: "Hvornår er strømmen billigst?",
      answer: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque, totam rem aperiam, eaque ipsa quae. lorem ipsum dolor sit amet. excepteur sint occaecat cupidtat non proident, sunt in culpa qui offcia deserunt mollit anim id est laborum."
    },
    {
      question: "Hvad er flexafregning?",
      answer: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque, totam rem aperiam, eaque ipsa quae. lorem ipsum dolor sit amet. excepteur sint occaecat cupidtat non proident, sunt in culpa qui offcia deserunt mollit anim id est laborum."
    },
    {
      question: "Hvor meget koster det?",
      answer: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque, totam rem aperiam, eaque ipsa quae. lorem ipsum dolor sit amet. excepteur sint occaecat cupidtat non proident, sunt in culpa qui offcia deserunt mollit anim id est laborum."
    },
    {
        question: "Hvad må du selv lave?",
        answer: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque, totam rem aperiam, eaque ipsa quae. lorem ipsum dolor sit amet. excepteur sint occaecat cupidtat non proident, sunt in culpa qui offcia deserunt mollit anim id est laborum."
      },
      {
        question: "Kan man trække det fra?",
        answer: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque, totam rem aperiam, eaque ipsa quae. lorem ipsum dolor sit amet. excepteur sint occaecat cupidtat non proident, sunt in culpa qui offcia deserunt mollit anim id est laborum."
      }
  ];