import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getAllContactInFormation } from "../../api/apifetch";
import { createNewsSubscription } from "../../api/apifetch";
import logo from "../../assets/img/logo.png";
import "./footer.scss";


const Footer = () => {


    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [footer, setFooter] = useState();
    const [getContactData, setGetContactData] = useState();


    useEffect(() => {

        setLoading(true)

        getAllContactInFormation().then((data) => {

            console.log(data)
            setFooter(data)
        })

            .catch((err) => {

                setError(true)
            })

            .finally(() => {

                setLoading(false)
            })

    }, [])


    const handleFormChange = event => {
        
        console.log(event.target.name);
        console.log(event.target.value);

        setGetContactData(prevState => ({
            ...prevState,
            [event.target.name]: event.target.value
        }));
    }



    let formBody = [];
    for (let property in getContactData) {
    let encodedKey = encodeURIComponent(property);
    let encodedValue = encodeURIComponent(getContactData[property]);
    formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");




    const handleSubmit = (e) => {

        e.preventDefault()
        
        setLoading(true)

        createNewsSubscription(formBody)

        .then((response) => {
            console.log(response)
        })

        .catch((err) => {
            console.log(err)
        })

        .finally(() => {
            setLoading(false)
        })

    }


    return (
        <footer>
            <div className="footer_font-container">
                <div className="footer_font-box">

                    <div className="footer_center-box">

                    <div className="footer_logo">
                        <img src={logo} alt="" />
                        <p>Som medlem af Elinstallatørernes
                            Landsorganisation, ELFO, er vi tilsluttet
                            et ankernævn og en garantiordning.</p>
                    </div>

                    <div className="footer_link">
                        <h3>Link</h3>

                        <li><Link to="/faq">&#62; Faq</Link></li>
                        <li><Link to="/aboutus">&#62; Om os</Link></li>
                        <li><Link to="/contactus">&#62; Kontakt os</Link></li>
                        <li><Link to="/service">&#62; Services</Link></li>
                    </div>

                    

                        <div className="footer_contact">
                            <h3>Kontakt os</h3>

                            <p><span>Adresse:</span> strømparken 1, 8500 Grenaa.</p>
                    
                            <p><span>Telefon:</span> [45] 69 16 68 56 14</p>
                            
                            <p><span>Email:</span> info@strøm.dk</p>
                        </div>

                    


                    <div className="footer_newsletter">
                        <h3>Nyhedsbrev</h3>

                        <p>Tilmeld dig vores nyhedsbrev her</p>

                        <form onChange={handleFormChange} onSubmit={handleSubmit}>
                            <input type="email" name="email" placeholder="Din Email" required />

                            <button type="submit">Tilmeld</button>
                        </form>
                    </div>

                    </div>
                </div>
            </div>
            <div className="footer_back-container">
                <div className="footer_back-box">
                <div className="footer_copy-right">
                    <h4><span>Strøm</span> © 2017 All Right Reserved</h4>
                </div>
                <div className="footer_social-icons">
                    <a href="#"><p>{String.fromCharCode(0xf09a)}</p></a>
                    <a href="#"><p>{String.fromCharCode(0xf099)}</p></a>
                    <a href="#"><p>{String.fromCharCode(0xf27d)}</p></a>
                    <a href="#"><p>{String.fromCharCode(0xf0e1)}</p></a>
                </div>
            </div>
            </div>
        </footer>
    );
}
export default Footer;