import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import { getAllAbout } from "../../api/apifetch";
import parser from "html-react-parser";
import img from "../../assets/img/10.jpg";
import "./whoAreWe.scss";


const WhoAreWe = () => {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [about, setAbout] = useState();


    useEffect(() => {
        
        setLoading(true)

        getAllAbout().then((data) => {

            console.log(data)
            setAbout(data)
        })

        .catch((err) => {

            setError(true)
        })
        
        .finally(() => {

            setLoading(false)
        })

    }, [] )

    return(
        <div className="whoarewe_container">
            <div className="whoarewe_center-container">
             <div className="whoarewe_text-box">
                    <div>
                        <p>Hvem er vi</p>
                        <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui offia deserunt mollit
                           anim id est laborum. Sed ut perspiciatis ude omnis natus error sit voluptatem accusan
                           tium doloremque laudantium totam rem aperiam eaque ipsa quae ab illo inventore
                           veritatis et quasi archite
                        </p>
                        <ul>
                            <li>Morbi fermentum felis nec</li>
                            <li>Fermentum felis nec gravida tempus.</li>
                        </ul>
                    </div>
                    <Link to="/contactus"><button>Kontakt os</button></Link>
                </div>
                
                <div className="whoarewe_img-box">
                    <img src={img} alt="" width="100%" />
                </div>
            </div>
        </div>
    );
}
export default WhoAreWe;