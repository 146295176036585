import React, {useState} from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Modal from "../../components/modal/Modal";
import "./menu.scss";


const Menu = () => {


    const [showMenu, setShowMenu] = useState(false)
    const [show, setShow] = useState(false);


    const navigate = useNavigate()


    const handleSearch = (e) => {

        e.preventDefault()
        console.log(e.target.inpSearch.value)
        navigate("/search/" + e.target.inpSearch.value)

    }


    const [fix, setFix] = useState(false);


   function setFixed() {
      if (window.scrollY >= 70) {
         setFix(true)
      } else {
         setFix(false)
      }
   }

   window.addEventListener("scroll", setFixed)

    return(
        <div className={fix ? 'menu_container fixedM' : 'menu_container'}>
        <nav className={fix ? 'Navbar fixedB' : 'Navbar'}>

            <div className={showMenu === true ? "burger-button open" : "burger-button"} onClick={() => setShowMenu(!showMenu)}>
                <span className="bar bar1"></span>
                <span className="bar bar2"></span>
                <span className="bar bar3"></span>
            </div>

            <div className={showMenu === true ? "navbar-container active" : "navbar-container"}>
            <ul>
                <li>
                    <NavLink to="/">Forside</NavLink>
                </li>
                <li>
                    <NavLink to="/aboutus">Om os</NavLink>
                </li>
                <li>
                    <NavLink to="/service">Service</NavLink>
                </li>
                <li>
                    <NavLink to="/faq">FAQ</NavLink>
                </li>
                <li>
                    <NavLink to="/news">Nyheder</NavLink>
                </li>
                <li>
                    <NavLink to="/contactus">Kontakt os</NavLink>
                </li>
            </ul>
            </div>
            <form className="SearchInput" onSubmit={handleSearch}>
            <input type="text" name="inpSearch" placeholder="Søg" />
            <button className="search-button" type="submit" onClick={() => setShow(true)}><span className="search-logo">{String.fromCharCode(0xf002)}</span></button>
            </form>
        </nav>
        <Modal onClose={() => setShow(false)} show={show} />
        </div>
    );
}
export default Menu;