import React, { useState, useEffect } from "react";
import { searchTours } from "../../api/apifetch";
import { useParams } from "react-router-dom";
import "./modal.scss";


const Modal = (props) => {


    


    let {q} = useParams()

    const [modal, setModal] = useState();
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);


    useEffect(() => {

        setLoading(true)

        searchTours(q)

        .then((response) => {

            setModal(response)
            setError(false)
        })

        .catch((err) => {

            setError(true)
            setModal()

        })

        .finally(() => {

            setLoading(false)

        })

    }, [q]);
    


    if (!props.show) {
        return null
    }

    return(

        <div>
        
        {
            modal && <div className="modal_container">


        <div className="modal" onClick={props.onClose}>
            <div className="modal-content" onClick={e => e.stopPropagation()}>
                <div className="modal-header">
                    <h4 className="modal-title"></h4>
                </div>
                <div className="modal-body">

                </div>
                <div className="modal-footer">
                    <button onClick={props.onClose} className="button">X</button>
                </div>
            </div>
        </div>

        </div>
        }
        </div>
        
    );
}

export default Modal;