import React, {useState} from "react";
import s3 from "../../assets/img/3s.jpg";
import n3 from "../../assets/img/3n.jpg";
import s10 from "../../assets/img/10.jpg";
import "./ourServicesPage.scss";



const OurServicesPage = () => {


    const [content, setContent] = useState("");
    const [clicked1, setClicked1] = useState(false);
    const [clicked2, setClicked2] = useState(false);
    const [clicked3, setClicked3] = useState(false);
    const [clicked4, setClicked4] = useState(false);

    const handleToggle1 = () => {
        setClicked1((prev) => !prev);
        setClicked2();
        setClicked3();
        setClicked4();
      };


      const handleToggle2 = () => {
        setClicked2((prev) => !prev);
        setClicked1();
        setClicked3();
        setClicked4();
      };


      const handleToggle3 = () => {
        setClicked3((prev) => !prev);
        setClicked1();
        setClicked2();
        setClicked4();
      };


      const handleToggle4 = () => {
        setClicked4((prev) => !prev);
        setClicked1();
        setClicked2();
        setClicked3();
      };


    const air_condition = () => {

        setContent(
            {
                picture: n3,
                big_text: "Air Condition",
                small_text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dui purus, bibendum ut ultricies vel, ultricies et nisl. Integer vitae leo ligula. Praesent sagittis placerat mollis. Maecenas a lacus feugiat, vestibulum felis vitae, congue orci. Aliquam vehicula quam quis eros scelerisque, scelerisque lobortis tortor mattis. Donec semper erat quis tortor bibendum, non malesuada metus egestas. Nulla a consequat felis, eget finibus velit. Maecenas vehicula tortor tortor, sed finibus urna eleifend id. Mauris aliquet mi non tincidunt mollis. Praesent gravida nisl quis urna posuere laoreet. Curabitur ultricies sapien suscipit, euismod ex sit amet, euismod turpis."
            }
        )
    }


    const sikkerhed = () => {

        setContent(
            {
                picture: s3,
                big_text: "Sikkerhed",
                small_text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dui purus, bibendum ut ultricies vel, ultricies et nisl. Integer vitae leo ligula. Praesent sagittis placerat mollis. Maecenas a lacus feugiat, vestibulum felis vitae, congue orci. Aliquam vehicula quam quis eros scelerisque, scelerisque lobortis tortor mattis. Donec semper erat quis tortor bibendum, non malesuada metus egestas. Nulla a consequat felis, eget finibus velit. Maecenas vehicula tortor tortor, sed finibus urna eleifend id. Mauris aliquet mi non tincidunt mollis. Praesent gravida nisl quis urna posuere laoreet. Curabitur ultricies sapien suscipit, euismod ex sit amet, euismod turpis."
            }
        ) 
    }


    const strom = () => {

        setContent(
            {
                picture: s10,
                big_text: "Strøm",
                small_text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dui purus, bibendum ut ultricies vel, ultricies et nisl. Integer vitae leo ligula. Praesent sagittis placerat mollis. Maecenas a lacus feugiat, vestibulum felis vitae, congue orci. Aliquam vehicula quam quis eros scelerisque, scelerisque lobortis tortor mattis. Donec semper erat quis tortor bibendum, non malesuada metus egestas. Nulla a consequat felis, eget finibus velit. Maecenas vehicula tortor tortor, sed finibus urna eleifend id. Mauris aliquet mi non tincidunt mollis. Praesent gravida nisl quis urna posuere laoreet. Curabitur ultricies sapien suscipit, euismod ex sit amet, euismod turpis."
            }
        )
    }


    const varme = () => {

        setContent(
            {
                picture: n3,
                big_text: "Varme",
                small_text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dui purus, bibendum ut ultricies vel, ultricies et nisl. Integer vitae leo ligula. Praesent sagittis placerat mollis. Maecenas a lacus feugiat, vestibulum felis vitae, congue orci. Aliquam vehicula quam quis eros scelerisque, scelerisque lobortis tortor mattis. Donec semper erat quis tortor bibendum, non malesuada metus egestas. Nulla a consequat felis, eget finibus velit. Maecenas vehicula tortor tortor, sed finibus urna eleifend id. Mauris aliquet mi non tincidunt mollis. Praesent gravida nisl quis urna posuere laoreet. Curabitur ultricies sapien suscipit, euismod ex sit amet, euismod turpis."
            }
        )
    }
    
    return(
        <div className="ourservicespage-container">
            <div className="ourservicespage_center-container">
                <div className="ourservicespage_buttons">
                    
                    <button className={`air-condition_button ${clicked1 ? "active" : ""}`} onClick={() => {air_condition(); handleToggle1()}}>Air Condition</button>

                    <button className={`sikkerhed_button ${clicked2 ? "active" : ""}`} onClick={() => {sikkerhed(); handleToggle2()}}>Sikkerhed</button>

                    <button className={`strom_button ${clicked3 ? "active" : ""}`} onClick={() => {strom(); handleToggle3()}}>Strøm</button>

                    <button className={`varme_button ${clicked4 ? "active" : ""}`} onClick={() => {varme(); handleToggle4()}}>Varme</button>

                </div>
                <div className="ourservicespage_content">

                    <img src={content.picture} alt="" />

                    <h3>{content.big_text}</h3>

                    <p>{content.small_text}</p>

                </div>
            </div>
        </div>
    );
}
export default OurServicesPage;