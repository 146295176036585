import React, {useState} from "react";
import Footer from "../../layout/footer/Footer";
import Header from "../../layout/header/Header";
import LittleAboutStrom2 from "../../layout/little_about_strom2/LittleAboutStrom";
import Menu from "../../layout/menu/Menu";
import Ourcustomers from "../../layout/our_customers/OurCustomers";
import OurTeam from "../../layout/our_team/OurTeam";
import PageName from "../../layout/page_name/PageName";
import WhoAreWe from "../../layout/who_are_we/WhoAreWe";
import "./aboutUs.scss";


const AboutUs = () => {


    const breadcrumbss = "Om os";
    

    return(
        <div className="aboutus_container">
            <Header />
            <Menu />
            <PageName breadcrumbs={breadcrumbss} />
            <LittleAboutStrom2 />
            <WhoAreWe />
            <Ourcustomers />
            <OurTeam />

            <Footer />
        </div>
    );
}
export default AboutUs;