import React, {useState, useEffect} from "react";
import { getAllTeam } from "../../api/apifetch";
import t1 from "../../assets/img/1t.png";
import t2 from "../../assets/img/2t.png";
import t3 from "../../assets/img/3t.png";
import t4 from "../../assets/img/4t.png";
import "./ourTeam.scss";

const OurTeam = () => {


    
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [teams, setTeams] = useState();


    useEffect(() => {
        
        setLoading(true)

        getAllTeam().then((data) => {

            console.log(data)
            setTeams(data)
        })

        .catch((err) => {

            setError(true)
        })
        
        .finally(() => {

            setLoading(false)
        })

    }, [] )

    return (
        <div className="center-home_container">
        <div className="content-home_conatiner">
        <div className="ourteam_container">

            <div className="ourteam_box-text">
                <h2>Vores <span>team</span></h2>

                <p>Lorem Ipsum is simply dummy text very cool</p>
            </div>


             <div className="ourteam_flex-container">

            

                <div className="ourteam_box">
                    <div className="overteam_layout-popup"></div>
                    <img src={t1} alt="" width="100%" />
                    <div className="overteam_popup">
                    <div className="overteam_icons">
                        <h4>Henrik Petersen</h4>
                        <p>Elektriker</p>
                        <span>{String.fromCharCode(0xf09a)}</span>
                        <span>{String.fromCharCode(0xf099)}</span>
                        <span>{String.fromCharCode(0xf0e1)}</span>
                        <span>{String.fromCharCode(0xf231)}</span>
                        </div>
                    </div>
                </div>


                <div className="ourteam_box">
                    <div className="overteam_layout-popup"></div>
                    <img src={t2} alt="" width="100%" />
                    <div className="overteam_popup">
                    <div className="overteam_icons">
                        <h4>Ninna Johansen</h4>
                        <p>Elektriker</p>
                        <span>{String.fromCharCode(0xf09a)}</span>
                        <span>{String.fromCharCode(0xf099)}</span>
                        <span>{String.fromCharCode(0xf0e1)}</span>
                        <span>{String.fromCharCode(0xf231)}</span>
                        </div>
                    </div>
                </div>


                <div className="ourteam_box">
                    <div className="overteam_layout-popup"></div>
                    <img src={t3} alt="" width="100%" />
                    <div className="overteam_popup">
                    <div className="overteam_icons">
                        <h4>Mads Jull</h4>
                        <p>Elektriker</p>
                        <span>{String.fromCharCode(0xf09a)}</span>
                        <span>{String.fromCharCode(0xf099)}</span>
                        <span>{String.fromCharCode(0xf0e1)}</span>
                        <span>{String.fromCharCode(0xf231)}</span>
                        </div>
                    </div>
                </div>


                <div className="ourteam_box">
                    <div className="overteam_layout-popup"></div>
                    <img src={t4} alt="" width="100%" />
                    <div className="overteam_popup">
                    <div className="overteam_icons">
                        <h4>Lars Jensen</h4>
                        <p>Elektriker</p>
                        <span>{String.fromCharCode(0xf09a)}</span>
                        <span>{String.fromCharCode(0xf099)}</span>
                        <span>{String.fromCharCode(0xf0e1)}</span>
                        <span>{String.fromCharCode(0xf231)}</span>
                        </div>
                    </div>
                </div>


            
            
            </div>
            
        </div>
        </div>
        </div>
    );
}
export default OurTeam;