import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import { getAllNews } from "../../api/apifetch";
import n1 from "../../assets/img/1n.jpg";
import n2 from "../../assets/img/2n.jpg";
import n3 from "../../assets/img/3n.jpg";
import "./lastNews.scss";


const LastNews = () => {

    

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [news, setNews] = useState();


    useEffect(() => {
        
        setLoading(true)

        getAllNews().then((data) => {

            console.log(data)
            setNews(data.sort((a, b) => {
                
                return Date.parse(b.received) -  Date.parse(a.received);
                
            }).slice(0,3))
        })

        .catch((err) => {

            setError(true)
        })
        
        .finally(() => {

            setLoading(false)
        })

    }, [] )


    

      

    return(
        <div className="center-home_container">
        <div className="content-home_conatiner">
        <div className="lastnews_container">

            <div className="lastnews_box-text">
                <h2>Sidste <span>nyt</span></h2>

                <p>Lorem Ipsum is simply dummy text very cool</p>
            </div>

             <div className="lastnews_flex-container">

            

                <div className="lastnews_box">
                    <span className="lastnews_bookmark">{String.fromCharCode(0xf02e)}</span>
                    <div className="lastnews_date">25 Maj.</div>
                    <img src={n1} alt="" width="100%" />

                    <h3>Ny lærling</h3>

                    <p>Lorem ipsum dolor amet consectetur adipisicing
                       elit sed elusm tempor incididunt ut labore dolore
                       mangna aliqua enim ad minim...</p>
                </div>


                <div className="lastnews_box">
                    <span className="lastnews_bookmark">{String.fromCharCode(0xf02e)}</span>
                    <div className="lastnews_date">12 Jun.</div>
                    <img src={n2} alt="" width="100%" />

                    <h3>Test dit forbrug</h3>

                    <p>Lorem ipsum dolor amet consectetur adipisicing
                       elit sed elusm tempor incididunt ut labore dolore
                       mangna aliqua enim ad minim...</p>
                </div>


                <div className="lastnews_box">
                    <span className="lastnews_bookmark">{String.fromCharCode(0xf02e)}</span>
                    <div className="lastnews_date">04 Jul.</div>
                    <img src={n3} alt="" width="100%" />

                    <h3>Få tjekket din varmepumpe</h3>

                    <p>Lorem ipsum dolor amet consectetur adipisicing
                       elit sed elusm tempor incididunt ut labore dolore
                       mangna aliqua enim ad minim...</p>
                </div>


            
            
            </div>
            
            <div className="lastnews_button">
            <Link to="/news"><button>Flere nyheder</button></Link>
            </div>
        </div>
        </div>
        </div>
    );
}
export default LastNews;