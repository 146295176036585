import React from "react";
import Contact from "../../layout/contact/Contact";
import Footer from "../../layout/footer/Footer";
import Header from "../../layout/header/Header";
import Menu from "../../layout/menu/Menu";
import PageName from "../../layout/page_name/PageName";
import "./contactUs.scss";


const ContactUs = () => {


    const breadcrumbss = "Kontakt os";
    

    return(
        <div className="contactus_container">
            <Header />
            <Menu />
            <PageName breadcrumbs={breadcrumbss}/>
            <Contact />

            <Footer />
        </div>
    );
}
export default ContactUs;