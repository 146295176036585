import React from "react";
import AllNews from "../../layout/all_news/AllNews";
import Footer from "../../layout/footer/Footer";
import Header from "../../layout/header/Header";
import Menu from "../../layout/menu/Menu";
import PageName from "../../layout/page_name/PageName";
import "./news.scss";

const News = () => {


    const breadcrumbss = "Nyheder";


    return(
        <div className="news_container">
            <Header />
            <Menu />
            <PageName breadcrumbs={breadcrumbss}/>
            <AllNews />

            <Footer />
        </div>
    );
}
export default News;