import s1 from "../../assets/img/2.jpg";
import s2 from "../../assets/img/1s.jpg";



export const SliderData = [
    {
        image: s1,
        big_text: "Hurtig service",
        small_text: "Når du skal bruge det"
    },
    {
        image: s2,
        big_text: "Bedste priser",
        small_text: "Vi matcher alle"
    }
]