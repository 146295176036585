import React from "react";
import Footer from "../../layout/footer/Footer";
import Header from "../../layout/header/Header";
import Menu from "../../layout/menu/Menu";
import OurServicesPage from "../../layout/our_services_page/OurServicesPage";
import PageName from "../../layout/page_name/PageName";
import "./service.scss";

const Service = () => {


    const breadcrumbss = "Service";


    return(
        <div className="service_container">
            <Header />
            <Menu />
            <PageName breadcrumbs={breadcrumbss} />
            <OurServicesPage />

            <Footer />
        </div>
    );
}
export default Service;