import React, {useState, useEffect} from "react";
import "./ourServices.scss";
import worker from "../../assets/img/1.png";
import { createBook, getAllServices } from "../../api/apifetch";


const OurServices = () => {


    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [message, setMessage] = useState();
    const [services, setServices] = useState();


    useEffect(() => {
        
        setLoading(true)

        getAllServices().then((data) => {

            console.log(data)
            setServices(data)
        })

        .catch((err) => {

            setError(true)
        })
        
        .finally(() => {

            setLoading(false)
        })

    }, [] )


    const handleSubmit = (e) => {

        e.preventDefault()
        
        setLoading(true)

        let formData = new FormData(e.target)

        createBook(formData)

        .then((response) => {
            console.log(response)
        })

        .catch((err) => {
            console.log(err)
        })

        .finally(() => {
            setLoading(false)
        })

    }

    return(
        <div className="center-home_container">
        <div className="content-home_conatiner">
        <div className="ourservices_container">
            <div className="ourservices_flex-container">
                <div className="ourservices_grid-container">

                    <div className="ourservices_container-text">
                       <h3>Vores <span>services</span></h3>
                       <p>Lorem Ipsum is simply dummy text of the printing</p>
                    </div>

                   

                    <div className="ourservices_services-box">
                        <span className="flaticon-lamp"></span>
                        <div className="ourservices_font-text-container">
                       <h4 className="ourservices_services-font-text">Strøm</h4>

                       <p>Lorem ipsum dolor sit amet constur adip
                          isicg elit sed do eiusmtempor incid dolore
                          magna
                       </p>
                       </div>
                    </div>

                    <div className="ourservices_services-box">
                        <span className="flaticon-technology"></span>
                        <div className="ourservices_font-text-container">
                       <h4 className="ourservices_services-font-text">Sikkerhed</h4>

                       <p>Lorem ipsum dolor sit amet constur adip
                          isicg elit sed do eiusmtempor incid dolore
                          magna
                       </p>
                       </div>
                    </div>

                    <div className="ourservices_services-box">
                        <span className="flaticon-fan"></span>
                        <div className="ourservices_font-text-container">
                       <h4 className="ourservices_services-font-text">Air Conditioning</h4>

                       <p>Lorem ipsum dolor sit amet constur adip
                          isicg elit sed do eiusmtempor incid dolore
                          magna
                       </p>
                       </div>
                    </div>

                    <div className="ourservices_services-box">
                        <span className="flaticon-air-conditioner-1"></span>
                        <div className="ourservices_font-text-container">
                       <h4 className="ourservices_services-font-text">Varme</h4>

                       <p>Lorem ipsum dolor sit amet constur adip
                          isicg elit sed do eiusmtempor incid dolore
                          magna
                       </p>
                       </div>
                    </div>

                        

                    
                </div>
                <div className="ourservices_img-box">
                    <img src={worker} alt="" width="100%" />
                </div>
            </div>
            <div className="ourservices_book-container">

                <div className="ourservices_book-text">

                <h2><span>Book</span> <br/> services nu</h2>

                </div>

                <div className="ourservices_book-box">

                <form onSubmit={handleSubmit}>

                   <input type="text" name="name" placeholder="Dit navn" required />

                   <input type="email" name="email" placeholder="Din Email" required />

                   <input type="tel" name="phone" placeholder="Telefon nr." required />

                   <button type="submit">Send</button>

                </form>
                </div>
            </div>

            {loading && <div>Loader</div>}
            {error && <div></div>}
        </div>
        </div>
        </div>
    );
}
export default OurServices;