import React, { useState, useEffect } from "react";
import {Link} from "react-router-dom";
import { getAllSlider } from "../../api/apifetch";
import { SliderData } from "./SliderData";
import parser from "html-react-parser";
import "./imgSlider.scss";


const ImgSlider = () => {

    const [current, setCurrent] = useState(0);
    const length = SliderData.length;

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [imgSlider, setImgSlider] = useState();


    useEffect(() => {
        
        setLoading(true)

        getAllSlider().then((data) => {

            console.log(data)
            setImgSlider(data)
        })

        .catch((err) => {

            setError(true)
        })
        
        .finally(() => {

            setLoading(false)
        })

    }, [] )


    

    const nextSlide = () => {
        setCurrent(current === length - 1 ? 0 : current + 1);
    }

    const prevSlide = () => {
        setCurrent(current === 0 ? length - 1 : current - 1);
    }


    if (!Array.isArray(SliderData) || SliderData.length <= 0) {
        return null;
    }

    return (
        <div className="slider">

            {SliderData.map((slide, index) => {
                return (
                    <div className={index === current ? 'slide active' : 'slide'} key={index}>

                        {index === current && (
                            <img src={slide.image} alt='' className="imgslider_image" />
                        )}

                        {index === current && (
                            <div className="slide-content"><p><span>{slide.big_text}</span><br/>{slide.small_text}</p><Link to="/contactus"><button>Kontakt os</button></Link></div>
                        )}

                    </div>
                );
            })}

            <div className="imgslider_arrow-container">
                <button className="left-button" onClick={nextSlide}><p>{String.fromCharCode(0xf060)}</p></button>
                <button className="right-button" onClick={prevSlide}><p>{String.fromCharCode(0xf061)}</p></button>
            </div>
        </div>
    );
};

export default ImgSlider;