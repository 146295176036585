import React from "react";
import ImgSlider from "../../components/img_slider/ImgSlider";
import Footer from "../../layout/footer/Footer";
import Header from "../../layout/header/Header";
import HelpFromStrom from "../../layout/help_from_strom/HelpFromStrom";
import LastNews from "../../layout/last_news/LastNews";
import LittleAboutStrom from "../../layout/little_about_strom/LittleAboutStrom";
import Menu from "../../layout/menu/Menu";
import Ourcustomers from "../../layout/our_customers/OurCustomers";
import OurServices from "../../layout/our_services/OurServices";
import OurTeam from "../../layout/our_team/OurTeam";
import "./home.scss";


const Home = () => {

    return(
        <div className="home_container">
            <Header />
            <Menu />
            <ImgSlider />
            <LittleAboutStrom />
            <HelpFromStrom />
            <OurServices />
            <Ourcustomers />
            <OurTeam />
            <LastNews />
            <Footer />
        </div>
    )
}
export default Home;