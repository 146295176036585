import { faqs } from "./AccordionData";
import AccordionItem from "./AccordionItem";
import "./accordion.scss";

const Accordion = () => {
  return (
    <div className="accordion-container">
    <div className="accordion_center-container">
    <ul className="accordion">
      {faqs.map((faq, index) => (
        <AccordionItem key={index} faq={faq} />
      ))}
    </ul>
    </div>
    </div>
  );
};

export default Accordion;
