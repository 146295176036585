import React from "react";
import "./ourCustomers.scss";
import person1 from "../../assets/img/3.jpg";
import person2 from "../../assets/img/4.jpg";
import person3 from "../../assets/img/99.jpg";

const Ourcustomers = () => {

    return(
        <div className="ourcustomers_container">
            <div className="ourcustomers_background-img">
            <div className="ourcustomers_box-text">
                <h3>Vores <span>Kunder siger</span></h3>
                <p>Lorem Ipsum is simply dummy text of the printing</p>
            </div>
            </div>
            <div className="ourcustomers_flex-container">
                <div className="ourcustomers_box">
                    <div className="ourcustomers_round-img">
                        <img src={person1} alt="" />
                    </div>
                    <h3>Peter fredriksen</h3>
                    <p className="ourcustomers_text">Happy Client</p>
                    <p className="ourcustomers_text2">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected</p>
                </div>

                <div className="ourcustomers_box">
                    <div className="ourcustomers_round-img">
                        <img src={person2} alt="" />
                    </div>
                    <h3>Mette jensen</h3>
                    <p className="ourcustomers_text">Happy Client</p>
                    <p className="ourcustomers_text2">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected</p>
                </div>

                <div className="ourcustomers_box">
                    <div className="ourcustomers_round-img">
                        <img src={person3} alt="" />
                    </div>
                    <h3>Simone tang</h3>
                    <p className="ourcustomers_text">Happy Client</p>
                    <p className="ourcustomers_text2">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected</p>
                </div>
            </div>
        </div>
    );
}
export default Ourcustomers;