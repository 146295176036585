// let urlTeam = "http://localhost:5333/team";
let urlTeam = "";

// let urlNews = "http://localhost:5333/news";
let urlNews = "";

// let urlContactinformation = "http://localhost:5333/contactinformation";
let urlContactinformation = "";

// let urlSlider = "http://localhost:5333/slider";
let urlSlider = "";


// let urlServices = "http://localhost:5333/service";
let urlServices = "";

// let urlAbout = "http://localhost:5333/about";
let urlAbout = "";

// let urlSearch = "http://localhost:5333/search/";
let urlSearch = "";


export const getAllTeam = () => {

    let res = fetch(urlTeam, {
            method: "GET",
            headers: {
                "content-type": "application/json; charset=utf-8"
            }
        })
        .then(response => response.json())

        return res

    }



    export const getAllNews = () => {

        let res = fetch(urlNews, {
                method: "GET",
                headers: {
                    "content-type": "application/json; charset=utf-8"
                }
            })
            .then(response => response.json())
    
            return res
    
        }



        export const getAllContactInFormation = () => {

            let res = fetch(urlContactinformation, {
                    method: "GET",
                    headers: {
                        "content-type": "application/json; charset=utf-8"
                    }
                })
                .then(response => response.json())
        
                return res
        
            }



            export const getAllSlider = () => {

                let res = fetch(urlSlider, {
                        method: "GET",
                        headers: {
                            "content-type": "application/json; charset=utf-8"
                        }
                    })
                    .then(response => response.json())
            
                    return res
            
                }



                export const getAllServices = () => {

                    let res = fetch(urlServices, {
                            method: "GET",
                            headers: {
                                "content-type": "application/json; charset=utf-8"
                            }
                        })
                        .then(response => response.json())
                
                        return res
                
                    }



                    export const getAllAbout = () => {

                        let res = fetch(urlAbout, {
                                method: "GET",
                                headers: {
                                    "content-type": "application/json; charset=utf-8"
                                }
                            })
                            .then(response => response.json())
                    
                            return res
                    
                        }
    



    export const getTourByID = (ID) => {

        let res = fetch("http://localhost:5333/news/" + ID, {
                method: "GET",
                headers: {
                    "content-type": "application/json; charset=utf-8"
                }
            })
            .then(response => response.json())
    
            return res
    
        }



        export const searchTours = (searchKey) => {

            let res = fetch(urlSearch + searchKey, {
                    method: "GET",
                    headers: {
                        "content-type": "application/json; charset=utf-8"
                    }
                })
                .then(response => response.json())
        
                return res
        
            }



    export const deleteAdminNews = (id) => {

        let res = fetch("http://localhost:5333/news/admin/" + id, {
                method: "DELETE",
                headers: {
                    "content-type": "application/json; charset=utf-8"
                }
            })
            .then(response => response.json())
    
            return res
    
        }

        


        export const createBook = (newBook) => {

            let res = fetch("http://localhost:5333/booking", {
                    method: "POST",
                    body: newBook
                })
                .then(response => response.json())
        
                return res
        
            }



            export const createContact = (newContact) => {

                let res = fetch("http://localhost:5333/contact", {
                        method: "POST",
                        body: newContact
                    })
                    .then(response => response.json())
            
                    return res
            
                }



            export const createNewsSubscription = (newNews) => {

                let res = fetch("http://localhost:5333/newssubscription", {
                    method: "POST",
                    headers: {
                        "content-type": "application/x-www-form-urlencoded; charset=utf-8"
                    },
                    body: newNews
                    })
                    .then(response => response.json())
            
                    return res
            
                }




                export const createAdminNews = (newAdminNews) => {

                    let res = fetch("http://localhost:5333/news/admin", {
                        method: "POST",
                        body: newAdminNews
                        })
                        .then(response => response.json())
                
                        return res
                
                    }
                



            export const editTours = (updatedTours, ID) => {

                let res = fetch("http://localhost:5333/news/admin/" + ID, {
                        method: "PUT",
                        body: updatedTours
                    })
                    .then(response => response.json())
            
                    return res
            
                }


            
    


