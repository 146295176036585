import React from "react";
import { Link } from "react-router-dom";
import "./helpFromStrom.scss";


const HelpFromStrom = () => {

    return(
        <div className="HelpFromStrom_container">
            <h2>Skal du bruge <span>hælp</span> fra <span>STRØM?</span></h2>

            <p>Lorem Ipsum is simply dummy text very cool</p>

            <Link to="/service"><button>Læs mere</button></Link>
        </div>
    );
}
export default HelpFromStrom;