import React from "react";


const AmountPerPage = (props) => {

    let setItemsPerPage = props.setItemsPerPage;
    let options = props.options;

    return(
        <select onChange={(e) => setItemsPerPage(+e.target.value)}>
            
            {
                options.map(o => 

                    <option value={o} key={o}>{o}</option>
                ) 
            }
        </select>
    );
}
export default AmountPerPage;