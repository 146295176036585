import {Routes, Route} from 'react-router-dom';
import Home from './pages/home/Home';
import AboutUs from './pages/about_us/AboutUs';
import Service from './pages/service/Service';
import Faq from './pages/faq/Faq';
import News from './pages/news/News';
import ContactUs from './pages/contact_us/ContactUs';
import AdminNews from './pages/admin/AdminNews';
import AdminNewsCreate from './layout/admin/AdminNewsCreate';
import AdminNewsEdit from './layout/admin/AdminNewsEdit';

const App = () => {
  
  return (
    <div className="App">
      <Routes>

        <Route path='/' element={<Home />} />
        <Route path='/aboutus' element={<AboutUs />} />
        <Route path='/service' element={<Service />} />
        <Route path='/faq' element={<Faq />} />
        <Route path='/news' element={<News />} />
        <Route path='/contactus' element={<ContactUs />} />
        {/* <Route path='/search/:q' element={<Home />} /> */}

        {/* <Route path='/admin/adminnews' element={<AdminNews />} />
        <Route path='/admin/adminnewscreate' element={<AdminNewsCreate />} />
        <Route path='/admin/adminnewsedit/:ID' element={<AdminNewsEdit />} /> */}


      </Routes>
    </div>
  );
}

export default App;
