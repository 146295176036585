import React from "react";
import Accordion from "../../components/accordion/Accordion";
import Footer from "../../layout/footer/Footer";
import Header from "../../layout/header/Header";
import Menu from "../../layout/menu/Menu";
import PageName from "../../layout/page_name/PageName";
import "./faq.scss";


const Faq = () => {


    const breadcrumbss = "Faq";


    return(
        <div className="faq_container">
            <Header />
            <Menu />
            <PageName breadcrumbs={breadcrumbss}/>
            <Accordion />

            <Footer />
        </div>
    );
}
export default Faq;